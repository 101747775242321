<template>
<div class="app-container">
  <!-- banner -->
  <div class="banner">
    <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
    <div class="left">
      <h2>Feedback</h2>
      <div class="intro-text">
        You are here:  HomePages / Feedback
      </div>
    </div>
  </div>
  <!-- cases -->
  <div class="contect columns">
    <div class="inner">
      <div class="center-header" v-if="pageData[1]">
        <h3>{{pageData[1].contentManagement.title}}</h3>
        <span>{{pageData[1].contentManagement.description}}</span>
      </div>
      <div class="contect-box dis-flex">
        <!-- 联系方式 -->
        <div class="contect-item">
          <div class="img">
            <img src="../../static/location.png" alt="">
          </div>
          <div class="icon-info" v-if="pageData[2]">
            <h4>{{pageData[2].contentManagement.title}}</h4>
            <div class="text ft-slim" style="text-align:left" v-html="pageData[2].contentManagement.content"></div>
          </div>
        </div>
        <div class="contect-item">
          <div class="img">
            <img src="../../static/contact.png" alt="">
          </div>
          <div class="icon-info" v-if="pageData[3]">
            <h4>{{pageData[3].contentManagement.title}}</h4>
            <div class="text ft-slim" v-html="pageData[3].contentManagement.content"></div>
          </div>
        </div>
        <div class="contect-item">
          <div class="img">
            <img src="../../static/email.png" alt="">
          </div>
          <div class="icon-info" v-if="pageData[4]">
            <h4>{{pageData[4].contentManagement.title}}</h4>
            <div class="text ft-slim" v-html="pageData[4].contentManagement.content"></div>
          </div>
        </div>
      </div>
      <div class="center-header">
        <h3>{{$t('common.contactUs')}}</h3>
      </div>
      <div class="form pos-rl">
        <el-form ref="elForm" :inline="true" :model="formData" size="large" label-position="top" v-if="formList.length!==0">
            <!-- <el-form-item label="" prop="authorityName" style="width:48%">
                <el-input v-model="formData.authorityName" placeholder="姓名" clearable >
                </el-input>
            </el-form-item>
            <el-form-item label="" prop="remark" style="width:48%">
                <el-input v-model="formData.remark" placeholder="邮箱" clearable >
                </el-input>
            </el-form-item>
            <el-form-item label="" prop="authorityName" style="width:48%">
                <el-input v-model="formData.authorityName" placeholder="手机号" clearable >
                </el-input>
            </el-form-item>
            <el-form-item label="" prop="remark" style="width:48%">
                <el-input v-model="formData.remark" placeholder="类别" clearable >
                </el-input>
            </el-form-item>
            <el-form-item label="" prop="remark" style="width:100%">
                <el-input type="textarea" v-model="formData.remark" rows="6" placeholder="内容" clearable >
                </el-input>
            </el-form-item> -->
            <el-form-item label="" prop="authorityName" style="width:99%">
                <el-input v-model="content" :placeholder="$t('common.content')" clearable >
                </el-input>
            </el-form-item>
            <div class="dynamic-item">
              <el-form-item label="" prop="name" v-for="(key,index) in formList" :key="index" :style="key.type !== '长文本'&&key.type !== 'Long text'?{'width':'48%'}:{'width':'99%'}">
                  <el-input v-model="key.value" v-if="key.type == '长文本'||key.type == 'Long text'" :placeholder="key.name" type="textarea" rows="5"></el-input>
                  <el-upload
                    class="upload-file"
                    :action="baseURL+'file/upload'"
                    :on-success="(res,file,fileList)=>handleSuccess(res,file,fileList,key)"
                    :limit="1"
                    v-else-if="key.type == '附件'||key.type == 'Attachment'"
                    :file-list="fileList">
                    <el-button size="small" type="primary">{{$t('common.attaUpload')}}</el-button>
                  </el-upload>
                  <el-input v-model="key.value" :placeholder="key.name"  v-else></el-input>
              </el-form-item>
            </div>
        </el-form>
        <div class="form-btn">
          <el-button class="read-btn" @click="submitForm()">{{$t('common.submitFeedback')}}</el-button>
        </div>
      </div>
    </div>
  </div>
  <!-- map -->
  <div class="map">
    <iframe src="static/map.html" frameborder="0" style="width:100%;height:100%" scrolling="no" v-if="language!=='en'"></iframe>
    <iframe src="static/map2.html" frameborder="0" style="width:100%;height:100%" scrolling="no" v-else></iframe>
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import baseURL from '@/api/baseURL.js'
import request from "@/utils/request.js";

export default {
  name: 'Home',
  // components: { InfoDialog, UpdatePassword,TagsView, sideMenu},
  data() {
    return {
      baseURL:baseURL,
      pageData:[],
      bannerData:{},
      parentData:{},
      formData:{},//动态表单数据
      formSet:{},//整个提交的数据
      content:'',
      formList:[],
      fileList:[],
      language:''
    }
  },
  methods: {
    getData(){
      if(this.parentData.id){
        post("/publicController/publicData",{id:this.parentData.id}).then(res => {
            if (res.code == 1) {
                this.pageData=res.data.business
                this.bannerData=res.data.homeIntroduction[0]||null
            } else {
                this.$message.error(res.data);
            }
        });
      }
    },
    getBgStyle(item){
      console.log(item,'item');
      if (item) {
        return {
        backgroundImage:
          'url(' + item.picture + ') ',
        }
      } else {
        return {
        backgroundImage:
          'url(' +
          require('../../static/news-banner.jpg') +
          ') ',
        }
      }
    },
    getImage(row) {
      setTimeout(function () {
        if (!row.pictureUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.picture
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.pictureUrl =  linkNode.href 
          })
        }
      }.bind(this), 1000);
    },
    // 获取表单格式
    getFormSetting(){
      post("/feedbackFormSettings/getPage",{
        page:1,
        limit:20
      }).then(res => {
          if (res.code == 1) {
              this.setFormList(res.data.data)
          } else {
              this.$message.error(res.data);
          }
      });
    },
   //  类型添加value
    setFormList(list) {
      if(list.length!==0){
        this.formList=list.map(ele=>{
          ele.value=''
          return ele
        })
        console.log(this.formList);
      }
    },
    //  创建表单对象
    setFormData(list) {
      let formdata = {}
      if(list.length!==0){
        list.map(ele=>{
          formdata[ele.name]=ele.value
        })
      }
      return formdata
    },
    // 文件上传成功
    handleSuccess(res, file, fileList,key){
      key.value= res.data.id
    },
    submitForm(){
      let data = this.setFormData(this.formList)
      let wholeData={
        formSettings:JSON.stringify(data),
        content:this.content
      }
      post("/customerFeedback/add",wholeData).then(res => {
          if (res.code == 1) {
            this.$message.success(this.$t('common.submitSuccess'));
          } else {
              this.$message.error(res.message);
          }
      });
    }
  },
  mounted() {
    this.parentData = this.$route.query
    console.log(this.parentData);
    this.getData()
    this.getFormSetting()
    // this.navIndex = this.$route.path
    if (Cookies.get('ClinLan') == 'en') {
      this.language = 'en'
    } else {
      this.language = 'cn'
    }
  },
}
</script>
<style lang="scss" scoped>
.inner{
  width: 1620px;
  margin:0 auto;
}
.columns{
  padding:100px 0
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}
.read-btn{
  color:#fc7700;
  background-color: #FFF5EC;
  border-color: #FFF5EC;
  border-radius: 25px;
  padding:17px 45px;
  margin:20px 0;
}

// banner
.banner{
  background-color: #F2F7FD;
  background-image: url('../../static/news-banner.jpg');
  background-size:cover;
  width: 100%;
  position: relative;
  height: 410px;
  overflow: hidden;
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:110px;
  color:#fff;

  h2{
    font-weight: 800;
    font-size: 76px;
  }
  .intro-text{
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
}
}
.contect{
  background-image: linear-gradient(110deg, #EBF3FD, #fff,#fff,#fff);
  height: 1300px;
  // height: 1110px;
  .contect-box{
    width: 1300px;
    margin: 80px auto;
    justify-content: space-between;
  }
  .contect-item{
    box-sizing: border-box;
    padding:40px;
    width:31%;
    height: 450px;
    background-color: #F2F6F9;
    border-radius: 20px;
    text-align: center;
    .img{
      width: 82px;
      height: 82px;
      overflow: hidden;
      margin:20px auto 40px;
    }
    .text{
      font-size: 16px;
      line-height: 2;
    }

  }
  .form{
    background: #ffffff;
    box-shadow: 0px 20px 150px 0px rgb( 246, 246, 246 );
    // height: 500px;
    width: 900px;
    margin:0 auto;
    padding:45px;
    border-radius: 20px;
    box-sizing: border-box;
    .form-btn{
      text-align: right;
    }
  }
  
}

// 地图
.map{
    background-color: #A8DAB5;
    height: 270px;
  #platform{
    display: none;
  }
  
}

::v-deep .el-form{
  .dynamic-item .el-form-item:nth-of-type(odd){
    margin-right: 25px;
  }
  .dynamic-item .el-form-item:nth-of-type(even){
    margin-right: 0;
  }
  .el-input__inner,.el-textarea__inner{
    background-color: #EDF1F6;
  }
  .el-input__inner{
    height: 50px;
    line-height: 50px;
  }
}
</style>
